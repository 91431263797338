<template>
  <div id="filterInputField">
    <div class="field">
      <template v-if="isDropDown">
        <div class="control">
          <div class="select">
            <select :id="`index-select-${indexSelect}`" v-model="fieldValue">
              <option disabled="disabled" value="default">Select Value</option>
              <option
                v-for="(option, index) in activeOptions"
                :key="index"
                :value="option.value"
              >{{ option.label }}</option>
            </select>
          </div>
        </div>
      </template>
      <template v-else-if="isDropDownMultiple">
        <div class="control">
          <multiselect
            :id="`index-select-${indexSelect}`"
            v-model="fieldValue"
            :options="activeOptions"
            :multiple="true"
            track-by="label"
            label="label"
            :close-on-select="false"
            :clear-on-select="false"
            placeholder="Select Value"
            :searchable="false"
          >
            <template slot="selection" slot-scope="{ values }">
              <span
                v-if="values.length"
                class="multiselect__single"
              >{{ values.length }} options selected</span>
            </template>
          </multiselect>
        </div>
      </template>
      <template v-else-if="isSingleText">
        <div class="control">
          <input
            :id="`index-select-${indexSelect}`"
            v-model="fieldValue"
            placeholder="Enter value"
            class="input"
            type="text"
          />
        </div>
      </template>
      <template v-else-if="isDateInput">
        <div class="control">
          <input :id="`index-select-${indexSelect}`" v-model="fieldValue" class="input" type="date" />
        </div>
      </template>
      <template v-else-if="isEmailInput">
        <div class="control">
          <input
            :id="`index-select-${indexSelect}`"
            v-model="fieldValue"
            class="input"
            type="email"
            placeholder="Enter value"
          />
        </div>
      </template>
      <template v-else-if="isNumericInput">
        <div class="control">
          <input
            :id="`index-select-${indexSelect}`"
            v-model.number="fieldValue"
            class="input"
            type="number"
            placeholder="Enter value"
          />
        </div>
      </template>
      <template v-else-if="isTimeInput">
        <div class="control">
          <input
            :id="`index-select-${indexSelect}`"
            v-model="fieldValue"
            class="input"
            type="time"
            placeholder="Enter value"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
  name: 'filterInputField',
  components: { Multiselect },
  props: {
    field: {
      type: Object
    },
    indexSelect: {
      type: Number
    }
  },
  data() {
    return {
      fieldValue: null,
      isDropDown: false,
      isSingleText: false,
      isDropDownMultiple: false,
      isDateInput: false,
      isNumericInput: false,
      isEmailInput: false,
      isTimeInput: false,
      activeOptions: []
    };
  },
  watch: {
    field() {
      this.isDropDown = false;
      this.isSingleText = false;
      this.isDropDownMultiple = false;
      this.isDateInput = false;
      this.isNumericInput = false;
      this.isEmailInput = false;
      this.isTimeInput = false;
      this.setField();
    },
    fieldValue(newVal) {
      if (!newVal || newVal === 'default') {
        this.$emit('fieldValueHasChanged', null);
        return;
      }
      if (newVal instanceof Array) {
        if (newVal.length) {
          const finalValue = [];
          newVal.forEach(obj => {
            finalValue.push(obj.value);
          });
          this.$emit('fieldValueHasChanged', finalValue);
        } else {
          this.$emit('fieldValueHasChanged', null);
        }
        return;
      }
      if (newVal && newVal !== 'default') {
        this.$emit('fieldValueHasChanged', this.fieldValue);
      }
    }
  },
  beforeMount() {
    this.setField();
  },
  methods: {
    setField() {
      switch (this.field.keyName) {
        case 'RadioButtonField':
        case 'DropDownInputField':
          this.activeOptions = this.field.options;
          this.field.dependencies.forEach(dep => {
            dep.options.forEach(optn => this.activeOptions.push(optn));
          });
          if (
            this.field.selectedOption &&
            this.field.selectedOption.hasOwnProperty('value')
          ) {
            this.field.selectedOption = this.field.selectedOption.value;
          }
          this.fieldValue = this.field.selectedOption;
          this.isDropDown = true;
          break;
        case 'CheckboxField':
          this.activeOptions = this.field.options;
          this.field.dependencies.forEach(dep => {
            dep.options.forEach(optn => this.activeOptions.push(optn));
          });
          this.fieldValue = [];
          this.isDropDownMultiple = true;
          break;
        case 'SingleLineInputField':
        case 'TextAreaField':
          this.fieldValue = null;
          this.isSingleText = true;
          break;
        case 'NumericInputField':
          this.fieldValue = null;
          this.isNumericInput = true;
          break;
        case 'EmailInputField':
          this.fieldValue = null;
          this.isEmailInput = true;
          break;
        case 'DateInputField':
          this.fieldValue = null;
          this.isDateInput = true;
          break;
        case 'TimeInputField':
          this.fieldValue = null;
          this.isTimeInput = true;
          break;
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
// Overwrite plugin css
.multiselect__select:before {
  border: 3px solid transparent;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: ' ';
  display: block;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 0.625em;
  border-color: #3273dc;
  right: 1.125em;
  z-index: 4;
}
.multiselect__tags {
  padding-top: 6px;
  min-height: 37px;
  border-color: #dbdbdb;
  .multiselect__placeholder {
    margin-bottom: 0;
    padding-top: 0;
    font-size: 16px;
    color: #4d4d4d;
  }
}
</style>
<style lang="scss" scoped>
#filterInputField {
  @extend %fieldContainer;
  .select {
    width: 100% !important;
    select {
      width: 100%;
    }
  }
}
</style>
